import React, { useCallback } from "react";
import { PageProps } from "gatsby";
import { makeStyles } from "@material-ui/core";
import { LayoutGoal, SEO } from "@components";
import { Edit } from "images/Goal";
import { useAuth, useURLParams } from "@hooks";
import { useGoals } from "@apollo";

const EditGoal: React.FC<PageProps> = () => {
  useAuth({ redirectIfNotLogged: true });
  const { goals } = useGoals();
  const classes = useStyles();
  const goal = useCallback(() => {
    if (useURLParams() !== undefined) {
      const goalfiltered = goals.filter(
        (obj) => obj.id == useURLParams()[0].id
      );
      return goalfiltered[0];
    } else {
      return undefined;
    }
  }, [goals]);
  return (
    <>
      <SEO />
      <LayoutGoal
        className={classes.header}
        secondTitle={goal()?.name}
        buttonContinue="Guardar Cambios"
        icon={Edit}
        goal={goal()?.goal}
        allData={goal()}
        goalId={goal()?.id}
        goalPeriod={goal()?.info.period}
        goalType={goal()?.type}
      />
    </>
  );
};

export default EditGoal;

const useStyles = makeStyles((theme) => ({
  header: {
    "& >section": {
      height: "7rem",
    },
  },
}));
